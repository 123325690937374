import {defineStore} from 'pinia'
import {computed, ref} from 'vue'

// enum for ticket types grouping
const TicketTypesGrouping = {
  CALENDAR: 'CALENDAR',
  SESSION: 'SESSION',
}

export const usePurchasingStore = defineStore('purchasing', () => {
  const router = useRouter()
  const localePath = useLocalePath()
  const authStore = useAuthStore()
  const cartStore = useCartStore()
  const {error: errorToast, success: successToast} = useToast()
  const {t} = useI18n()
  const GENERIC_ERROR_MESSAGE = t('event_seating_plan_picker.something_went_wrong')
  const selectedEventSlug = ref(null)
  // TODO: Need to work on if locales are changed while purchasing ticket flow
  const eventDetails = ref({})
  const eventLocked = ref(false)
  const showEventUnlockModal = ref(false)

  const showAvailability = computed(() => {
    if (eventDetails.value) {
      const ticketTypesGrouping = eventDetails.value.ticket_types_grouping
      if (
        ticketTypesGrouping.includes(TicketTypesGrouping.CALENDAR) ||
        ticketTypesGrouping.includes(TicketTypesGrouping.SESSION)
      ) {
        return true
      }
    }
    return false
  })

  const setSelectedEventSlug = (newSelectedEventSlug) => {
    selectedEventSlug.value = newSelectedEventSlug
  }

  const setEventDetails = (newEventDetails) => {
    eventDetails.value = newEventDetails

    if (newEventDetails.is_locked) {
      eventLocked.value = true
    }
  }

  const unlockEvent = async (eventSlug, unlockCode) => {
    // const apiUrl = 'event/' + eventSlug + '/unlock'
    // const response = await $fetch(useRuntimeConfig().public.apiBaseUrl + '/' + apiUrl, {
    //   method: 'POST',
    //   body: {
    //     unlock_code: unlockCode,
    //   },
    // })

    // if (response.status == 200) {
    //   eventLocked.value = false
    //   showEventUnlockModal.value = false
    // } else {
    // }
    eventLocked.value = false
    showEventUnlockModal.value = false
  }

  const hasSeatingPlan = computed(() => {
    return eventDetails.value?.has_seating_plan
  })

  const eventSelectionMethod = computed(() => {
    if (!hasSeatingPlan.value) return false
    if (eventDetails.value.enable_ba_by_category) {
      return SEATING_TYPES.BA_BY_CATEGORY
    }
    if (eventDetails.value.enable_ba_by_self_select) {
      return SEATING_TYPES.BA_BY_SECTION
    }
    return eventDetails.value.seating_type
  })

  const dates = ref([])

  const setEventDates = (newDates) => {
    dates.value = newDates
  }

  const selectedDate = ref(null)
  const selectedSession = ref(null)
  const sessionsPending = ref(false)
  const selectedAreaId = ref(null)

  const ticket = ref(null)
  const ticketCategories = ref(null)
  const ticketsLocked = ref(false)
  const buyingTickets = ref(false)

  const seatingPlanPicked = ref(false)

  const setSeatingPlanPicked = (newSeatingPlanPicked) => {
    seatingPlanPicked.value = newSeatingPlanPicked
  }

  const setAreaId = (newAreaId) => {
    selectedAreaId.value = newAreaId
  }

  const selectedTickets = computed(() => {
    if (!ticket.value) {
      return []
    }

    const selectedTickets = []
    Object.keys(ticket.value).forEach((categoryId) => {
      const categorySelection = ticket.value[categoryId]
      Object.keys(categorySelection).forEach((ticketTypeId) => {
        const qty = categorySelection[ticketTypeId]
        if (qty > 0) {
          const category = ticketCategories.value.find((category) => {
            return category.id == categoryId
          })
          const ticketType = category.ticket_types.find((ticketType) => {
            return ticketType.id == ticketTypeId
          })
          // TODO: Need to work on if locales are changed while purchasing ticket flow
          // TODO: If only one category, then no need to show category name
          selectedTickets.push({
            categoryId: categoryId,
            categoryName: category.name,
            ticketTypeId: ticketTypeId,
            ticketName: ticketType.name,
            price: ticketType.price,
            quantity: qty,
            isSeated: category.is_seated,
            seatColor: category.seat_colour,
          })
        }
      })
    })

    return selectedTickets
  })

  const reservePending = ref(false)

  const prices = ref([]) // Array to store ticket prices
  const promoCode = ref(null) // Promo information
  const comboPrices = ref([]) // Array to store combo prices

  const totalPrices = computed(() => {
    if (!selectedTickets.value.length) return []
    return selectedTickets.value.reduce((acc, ticket) => {
      return acc + parseInt(ticket.price) * parseInt(ticket.quantity)
    }, 0)
  })

  const orderReserveData = ref(null)

  const showMoreEvents = computed(() => orderReserveData.value && orderReserveData.value.has_upsell_events)

  const orderMoreEventsData = ref(null)

  const setOrderReserveData = (newOrderReserveData) => {
    orderReserveData.value = newOrderReserveData
  }

  const setOrderMoreEventsData = (newOrderMoreEventsData) => {
    orderMoreEventsData.value = newOrderMoreEventsData
  }

  const ticketDataFields = computed(() => {
    if (!orderReserveData.value?.orders) return []

    return orderReserveData.value.orders.reduce((acc, order) => {
      if (order.data_fields && Object.keys(order.data_fields).length > 0) {
        acc.push({orderId: order.order_id.toString(), dataFields: order.data_fields})
      }
      return acc
    }, [])
  })

  const orderDataFields = computed(() => {
    const orderDataFields = orderReserveData.value?.order_data_fields

    if (!orderDataFields || Object.keys(orderDataFields).length === 0) return []

    return Object.entries(orderDataFields).reduce((acc, [orderId, orderFields]) => {
      if (Object.keys(orderFields).length > 0) {
        acc.push({orderId, orderFields})
      }
      return acc
    }, [])
  })

  const showTicketDetails = computed(() => {
    if (!orderReserveData.value) return false
    return ticketDataFields.value.length > 0 || orderDataFields.value.length > 0
  })

  /**
   * @typedef {Object.<string, Object.<string, Object.<string, string | number>>>} TicketFormData
   * Represents the structure of the eventTicketsFormData.
   */

  /**
   * @type {import('vue').Ref<TicketFormData | null>}
   */
  const eventTicketsFormData = ref(null)

  /**
   * @typedef {Object.<string, Object.<string, { data_field_id: string }>>} OrderFormData
   * Represents the structure of the eventOrderFormData.
   */

  /**
   * @type {import('vue').Ref<OrderFormData | null>}
   */
  const eventOrderFormData = ref(null)

  const setEventTicketsFormData = (newEventTicketsFormData) => {
    eventTicketsFormData.value = newEventTicketsFormData
  }

  const setEventOrderFormData = (newEventOrderFormData) => {
    eventOrderFormData.value = newEventOrderFormData
  }

  const getReservePayload = (categoryId) => {
    const reserves = []

    const categoryIds = new Set()

    let categoryToReserve = selectedTickets.value

    if (categoryId) {
      categoryToReserve = selectedTickets.value.filter((ticket) => ticket.categoryId === categoryId)
    }

    categoryToReserve.forEach((ticket) => {
      const categoryId = parseInt(ticket.categoryId, 10)

      if (!categoryIds.has(categoryId)) {
        categoryIds.add(categoryId)
        reserves.push({
          ticket_category_id: categoryId,
          ticket_types: [],
        })
      }

      const reserveIndex = reserves.findIndex((reserve) => reserve.ticket_category_id === categoryId)
      reserves[reserveIndex].ticket_types.push({
        qty: ticket.quantity,
        ticket_type_id: ticket.ticketTypeId,
      })
    })

    const payload = {
      session_id: selectedSession.value.id,
      reserves,
      // accept_non_adjacent_seats: true, // optional
    }

    if (promoCode.value) {
      payload.campaign_code = promoCode.value
    }

    if (selectedAreaId.value) {
      payload.area_id = selectedAreaId.value
    }

    if (orderReserveData.value?.order_group_id) {
      payload.order_group_id = orderReserveData.value.order_group_id
    }

    return payload
  }

  const reserveTicket = async (categoryId) => {
    reservePending.value = true

    const payload = getReservePayload(categoryId)

    try {
      const url = useRuntimeConfig().public.apiBaseUrl + `/event/${eventDetails.value.id}/reserve/`

      const response = await $authFetch(url, {
        method: 'POST',
        body: payload,
      })

      setOrderReserveData(response.data)

      return response.data
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      reservePending.value = false
    }
  }

  const getOrderMoreEventsPayload = (orderGroupId, eventId) => {
    return {
      order_group_id: orderGroupId || orderReserveData.value.order_group_id,
      event_id: eventId || eventDetails.value.id,
      country_code: useRuntimeConfig().public.purchasingStore.upsellCountryCode,
    }
  }

  const getOrderMoreEvents = async (orderGroupId, eventId) => {
    const payload = getOrderMoreEventsPayload(orderGroupId, eventId)
    try {
      const url = `/orders/upsell/`

      const response = await $fetch(`${useRuntimeConfig().public.apiBaseUrl}${url}`, {
        method: 'POST',
        body: payload,
      })
      setOrderMoreEventsData(response.data.events)
      return response.data.events
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const festival = ref(null)
  const orderGroupTimer = ref(0) // Timer for order group
  const orderData = ref(null)
  const paymentBlocked = ref(false)
  const paymentPending = ref(false)
  let paymentData = reactive({}) // Payment information
  const thanksData = ref(null) // Data for redirection after purchase

  const getTicket = () => {
    return ticket.value
  }

  const setTicket = (newTicket) => {
    ticket.value = newTicket
  }

  const ticketCount = computed(() => {
    if (!ticket.value) return 0

    let count = 0
    for (var tcKey in ticket.value) {
      for (var ttKey in ticket.value[tcKey]) {
        count += ticket.value[tcKey][ttKey]
      }
    }
    return count
  })

  const setTicketCategories = (newTicketCategories) => {
    ticketCategories.value = newTicketCategories
  }

  const updateDataFieldsPending = ref(false)

  const getDataFieldPayload = () => {
    /**
     * @type {Object} Payload
     * @property {Array<Object>} orders - An array of orders.
     * @property {string} orders[].order_id - The ID of the order.
     * @property {Object} orders[].updates - Updates related to the order.
     * @property {Object} orders[].updates.ticketTmpId - Ticket temporary ID information.
     * @property {string} orders[].updates.ticketTmpId.data_field_id - The data field ID.
     */
    const payload = {orders: []}

    for (let orderId in eventTicketsFormData.value) {
      const order = {order_id: orderId, updates: {}}
      for (let ticketTmpId in eventTicketsFormData.value[orderId]) {
        const dataField = eventTicketsFormData.value[orderId][ticketTmpId]
        const update = {[ticketTmpId]: dataField}
        order.updates[ticketTmpId] = update[ticketTmpId]
      }
      payload.orders.push(order)
    }

    return payload
  }

  const updateDataFields = async () => {
    if (eventTicketsFormData.value === null) return false

    updateDataFieldsPending.value = true

    const payload = getDataFieldPayload()

    try {
      const url = `/event/${eventDetails.value.id}/data-fields/update`

      const response = await $fetch(`${useRuntimeConfig().public.apiBaseUrl}${url}`, {
        method: 'POST',
        body: payload,
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      updateDataFieldsPending.value = false
    }
  }

  const updateOrderDataFieldsPending = ref(false)

  const getOrderDataFieldPayload = () => {
    return {
      orders: eventOrderFormData.value,
    }
  }

  const updateOrderDataFields = async () => {
    if (eventOrderFormData.value === null) return false

    updateOrderDataFieldsPending.value = true

    const payload = getOrderDataFieldPayload()

    try {
      const url = `/event/${eventDetails.value.id}/data-fields/order/update`

      const response = await $fetch(`${useRuntimeConfig().public.apiBaseUrl}${url}`, {
        method: 'POST',
        body: payload,
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      updateOrderDataFieldsPending.value = false
    }
  }

  const skipGuard = ref(false)

  const setSkipGuard = (newSkipGuard) => {
    skipGuard.value = newSkipGuard
  }

  const getDestinationRoute = (
    routeName,
    slug,
    {isLoggedIn, showAvailability, showMoreEvents, hasTicketDataFields, hasOrderDataFields},
  ) => {
    if (routeName.includes('event-slug_')) {
      if (showAvailability) {
        return localePath({name: 'event-slug-dates', params: {slug}})
      } else {
        return localePath({name: 'event-slug-tickets', params: {slug}})
      }
    }

    if (routeName.includes('event-slug-dates_')) {
      return localePath({name: 'event-slug-tickets', params: {slug}})
    }

    if (routeName.includes('event-slug-tickets_')) {
      if (isLoggedIn) {
        if (hasTicketDataFields || hasOrderDataFields) {
          return localePath({name: 'event-slug-data', params: {slug}})
        }
        if (showMoreEvents) {
          return localePath({name: 'event-slug-more', params: {slug}})
        } else {
          return localePath({name: 'event-slug-payment', params: {slug}})
        }
      } else {
        return {authView: true}
      }
    }

    if (routeName.includes('event-slug-data_')) {
      if (showMoreEvents) {
        return localePath({name: 'event-slug-more', params: {slug}})
      } else {
        return localePath({name: 'event-slug-payment', params: {slug}})
      }
    }

    if (routeName.includes('event-slug-more_')) {
      return localePath({name: 'event-slug-payment', params: {slug}})
    }
  }

  const buyTickets = async () => {
    buyingTickets.value = true
    // useRoute() is misbehaving, so using router.currentRoute.value
    const route = router.currentRoute.value

    if (route.name.includes('event-slug-tickets_') && authStore.isLoggedIn) {
      try {
        const response = await reserveTicket()
        if (response) {
          const time = response.remaining_time
          startOrderGroupTimer(time)
        }
      } catch (error) {
        console.error(error)
        buyingTickets.value = false
        // show error toast
        return false
      }
    }

    if (route.name.includes('event-slug-data_')) {
      try {
        const response = await updateDataFields()
        if (response) {
          console.log('Data fields updated', response.data)
        }
      } catch (error) {
        console.error(error)
        buyingTickets.value = false
        return false
      }

      try {
        const response = await updateOrderDataFields()
        if (response) {
          console.log('Order data fields updated', response.data)
        }
      } catch (error) {
        console.error(error)
        buyingTickets.value = false
        return false
      }
    }

    const destination = getDestinationRoute(route.name, route.params.slug, {
      showAvailability: showAvailability.value,
      showMoreEvents: showMoreEvents.value,
      isLoggedIn: authStore.isLoggedIn,
      hasTicketDataFields: ticketDataFields.value.length > 0,
      hasOrderDataFields: orderDataFields.value.length > 0,
    })

    if (destination !== null) {
      if (destination.authView) {
        authStore.toggleAuthView(true)
        buyingTickets.value = false
        return false
      }
      router.push(destination)
    }

    buyingTickets.value = false
  }

  const handleAddToCart = (response) => {
    if (response) {
      const time = response.remaining_time
      startOrderGroupTimer(time)
    }
    cartStore.add({
      event: eventDetails.value,
      date: selectedDate.value,
      session: selectedSession.value,
      tickets: selectedTickets.value,
      orderReserveData: orderReserveData.value,
      prices: orderReserveData.value.prices,
      total: orderReserveData.value.total_price_with_fee,
    })
    cartStore.setOrderGroupId(orderReserveData.value.order_group_id)
  }

  const addToCart = async () => {
    buyingTickets.value = true
    try {
      const response = await reserveTicket()
      handleAddToCart(response)
    } catch (error) {
      console.error(error)
    } finally {
      buyingTickets.value = false
    }
  }

  const setDate = (date) => {
    selectedDate.value = date
  }

  const setSession = (sessionId) => {
    selectedSession.value = sessionId
  }

  const setSessionPending = (newSessionPending) => {
    sessionsPending.value = newSessionPending
  }

  const updatePrices = (newPrices) => {
    prices.value = newPrices
  }

  const setPromoCode = (newPromoCode) => {
    promoCode.value = newPromoCode
  }

  const updateComboPrices = (newComboPrices) => {
    comboPrices.value = newComboPrices
  }

  const updateFestival = (newFestival) => {
    festival.value = newFestival
  }

  const startOrderGroupTimer = (time) => {
    if (!time) return
    const [minutes, seconds] = time.split(':').map(Number)
    const totalTimeInMilliseconds = (minutes * 60 + seconds) * 1000
    orderGroupTimer.value = totalTimeInMilliseconds
  }

  const extendTimer = async () => {
    const url = `/orders/timer`

    const payload = {
      order_group_id: orderReserveData.value.order_group_id,
    }

    try {
      const response = await $fetch(`${useRuntimeConfig().public.apiBaseUrl}${url}`, {
        method: 'POST',
        body: payload,
      })

      if (response.data) {
        orderGroupTimer.value = 0
        const time = response.data.remaining_time
        startOrderGroupTimer(time)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const setPaymentBlocked = (newPaymentBlocked) => {
    paymentBlocked.value = newPaymentBlocked
  }

  const paymentBlockedRedirect = () => {
    router.replace(localePath({name: 'index'}))
  }

  const setOrderData = (newOrderData) => {
    orderData.value = newOrderData
  }

  const setPaymentData = (newPaymentData) => {
    const payload = {
      ...paymentData,
      ...newPaymentData,
    }
    paymentData = payload
  }

  const setThanksData = (newThanksData) => {
    thanksData.value = newThanksData
  }

  const makePayment = async () => {
    paymentPending.value = true

    const payload = {
      order_group_id: orderReserveData.value.order_group_id,
      third_party_reference: orderReserveData.value.reference,
      payments: [
        {
          amount: Number(orderReserveData.value.total_price_with_fee),
          key: '',
          method: 'CC',
        },
      ],
    }

    try {
      const url = useRuntimeConfig().public.apiBaseUrl + `/event/${eventDetails.value.id}/complete/`

      const response = await $authFetch(url, {
        method: 'POST',
        body: payload,
      })

      if (!response.data || response.statusCode >= 400 || response?.data?.errors?.length > 0) {
        paymentPending.value = false
        const errors = response?.data?.errors
        errorToast(errors?.map((error) => error.message)?.join('\n') || GENERIC_ERROR_MESSAGE)
        return
      }

      const pollingUrl = useRuntimeConfig().public.apiBaseUrl + `/event/${eventDetails.value.id}/complete`
      const pollingParams = {
        task_id: response.data.task_id,
      }

      const successCallback = (data) => {
        paymentPending.value = false
        setThanksData(data)
        router.push(localePath({name: 'checkout-thanks'}))
      }

      const {pollingInterval, maxPollingAttempts} = useRuntimeConfig().public.purchasingStore.payment
      let pollingAttempts = 0

      const pollApi = async () => {
        try {
          const pollingResponse = await $authFetch(pollingUrl, {
            method: 'GET',
            params: pollingParams,
          })

          if (
            !pollingResponse.data ||
            pollingResponse.statusCode >= 400 ||
            pollingResponse?.data?.errors?.length > 0
          ) {
            paymentPending.value = false
            const errors = response?.data?.errors
            errorToast(errors?.map((error) => error.message)?.join('\n') || GENERIC_ERROR_MESSAGE)
            return
          }

          if (pollingResponse.data && pollingResponse.data.task_id) {
            // Retry the API again
            pollingAttempts++
            if (pollingAttempts < maxPollingAttempts) {
              setTimeout(pollApi, pollingInterval)
            } else {
              console.error('Max polling attempts reached')
              // TODO: Replace with order error page
              paymentPending.value = false
              setSkipGuard(true)
              router.replace(localePath({name: 'index'}))
            }
          } else {
            // End the polling
            successCallback(pollingResponse.data)
          }
        } catch (error) {
          console.error(error)
        }
      }

      setTimeout(pollApi, pollingInterval)
    } catch (error) {
      paymentPending.value = false
      console.error(error)
    }
  }

  const cancelPending = ref(false)

  const cancelOrder = async () => {
    cancelPending.value = true
    const payload = {
      reference: orderReserveData.value.reference,
      order_id: orderReserveData.value.order_id,
    }
    try {
      const url = useRuntimeConfig().public.apiBaseUrl + `/event/${eventDetails.value.id}/cancel`

      const response = await $authFetch(url, {
        method: 'POST',
        body: payload,
      })

      if (response.data) {
        console.log('Order cancelled: ', payload, response.data)
        setSkipGuard(true)
        router.replace(localePath({name: 'index'}))
      }
    } catch (error) {
      console.error(error)
    } finally {
      cancelPending.value = false
    }
  }

  // Processing

  const onPayment = () => {
    // Convert the ticket selection to the request payload structure
    const requestPayload = convertToRequestPayload(ticketSelection.value)
    // Make the API request with requestPayload
    // Replace this with your API request code
    console.log(JSON.stringify(requestPayload, null, 2))
  }

  // Function to convert ticket selection to request payload
  const convertToRequestPayload = (ticketSelection) => {
    // TODO: Replace the hardcoded values with the actual values from the store
    const requestPayload = {
      session_id: selectedSession.value.id, // Replace with actual session ID
      ticket_category_id: 12, // Replace with actual category ID, Need to be an Array
      ticket_types: [],
      combos: [],
      campaign_code: 'string', // Replace with actual campaign code
      area_id: 'COMPANY_EMP15', // Replace with actual area ID
      order_group_id: 23456, // Replace with actual order group ID
      accept_non_adjacent_seats: true, // Replace with actual value
    }

    // tickets processing
    Object.keys(ticketSelection).forEach((categoryId) => {
      const categorySelection = ticketSelection[categoryId]
      requestPayload.ticket_category_id = categoryId
      Object.keys(categorySelection).forEach((ticketTypeId) => {
        const qty = categorySelection[ticketTypeId]
        if (qty > 0) {
          if (categoryId === 'combo') {
            requestPayload.combos.push({
              combo_id: ticketTypeId,
              combo_qty: qty,
            })
          } else {
            requestPayload.ticket_types.push({
              ticket_type_id: ticketTypeId,
              ticket_type_qty: qty,
            })
          }
        }
      })
    })

    return requestPayload
  }

  const purchasingBusy = computed(() => {
    return (
      buyingTickets.value ||
      paymentPending.value ||
      reservePending.value ||
      updateOrderDataFieldsPending.value ||
      updateDataFieldsPending.value ||
      sessionsPending.value
    )
  })

  const purchasingDisabled = computed(() => {
    if (!eventDetails.value) return true
    if (router.currentRoute.value.name.includes('event-slug-dates_') && !selectedDate.value) return true
    // date selected but no session selected
    if (selectedDate.value && !selectedSession.value) {
      return true
    }
    // date and session selected but no id
    if (
      selectedDate.value &&
      selectedSession.value &&
      selectedSession.value.id === null &&
      selectedTickets.value === undefined
    ) {
      return true
    }

    // if route is event-slug-tickets and no ticket selected
    if (router.currentRoute.value.name.includes('event-slug-tickets_') && selectedTickets.value.length === 0) {
      return true
    }

    return (
      buyingTickets.value ||
      paymentPending.value ||
      reservePending.value ||
      updateOrderDataFieldsPending.value ||
      updateDataFieldsPending.value ||
      sessionsPending.value ||
      ticketsLocked.value ||
      eventLocked.value
    )
  })

  const handleEventTicketTypesGrouping = async (event, dates, slug, eventDataStore) => {
    const eventTicketTypesGrouping = event.ticket_types_grouping || []
    const isSingleDate = dates.length === 1
    const hasCalendarGrouping = eventTicketTypesGrouping.includes(TicketTypesGrouping.CALENDAR)
    const hasSessionGrouping = eventTicketTypesGrouping.includes(TicketTypesGrouping.SESSION)

    const commonData = {
      redirectToPage: null,
      selectedDate: '',
      selectedSession: null,
    }

    if (eventTicketTypesGrouping.length === 0 || eventTicketTypesGrouping.includes('NONE')) {
      if (isSingleDate) {
        const selectedDate = dates[0]
        const sessions = await eventDataStore.getEventSessions(event.id, selectedDate)
        if (sessions.length === 1) {
          const session = sessions[0].sessions[0]
          return {
            ...commonData,
            redirectToPage: `/event/${slug}/tickets`,
            selectedDate,
            selectedSession: session,
          }
        }
        console.error(`Event ${slug} has more than 1 session but no ticket type grouping`)
      } else {
        console.error(`Event ${slug} has more than 1 date but no ticket type grouping`)
      }
    } else {
      if (!hasCalendarGrouping) {
        return {
          ...commonData,
          redirectToPage: `/event/${slug}/dates`,
        }
      } else if (!hasSessionGrouping) {
        return commonData
      }
    }

    return commonData
  }

  const getSeatingPlanStatelessBase = async () => {
    const payload = {
      session_id: selectedSession.value.id,
      full_depth: false,
    }

    const url = `/event/${eventDetails.value.id}/seating-plan/base`
    const response = await $fetch(`${useRuntimeConfig().public.apiBaseUrl}${url}`, {
      method: 'post',
      body: payload,
    })

    return response.data
  }

  const getSeatingPlanStateBase = async () => {
    const payload = {
      session_id: selectedSession.value.id,
      include_tt_max_spans: 1,
      include_max_spans_per_section: 1,
    }

    const url = `/event/${eventDetails.value.id}/seating-plan/state`
    const response = await $fetch(`${useRuntimeConfig().public.apiBaseUrl}${url}`, {
      method: 'post',
      body: payload,
    })

    return response.data
  }

  const getSeatingPlanArea = async (areaId, areaSvgobjectId) => {
    const payload = {
      area_id: areaId,
      area_svg_object_id: areaSvgobjectId,
      session_id: selectedSession.value.id,
    }

    const url = `/event/${eventDetails.value.id}/seating-plan/area`

    const response = await $fetch(`${useRuntimeConfig().public.apiBaseUrl}${url}`, {
      method: 'post',
      body: payload,
    })

    return response
  }

  const handleExpiredToken = async () => {
    errorToast(t('event_seating_plan_picker.error_session_expired'))
    try {
      await authStore.refresh()

      successToast(t('event_seating_plan_picker.error_session_expired_refresh_success'))
    } catch (error) {
      errorToast(t('event_seating_plan_picker.error_session_expired_refresh_failed'))

      setTimeout(() => {
        router.replace(localePath({name: 'index'}))
      }, 5000)
    }
  }

  const resetStore = () => {
    selectedEventSlug.value = null
    eventDetails.value = null
    eventLocked.value = false
    showEventUnlockModal.value = false

    dates.value = []
    selectedDate.value = null
    selectedSession.value = null
    selectedAreaId.value = null
    seatingPlanPicked.value = false

    orderReserveData.value = null
    orderMoreEventsData.value = null
    ticket.value = null
    ticketCategories.value = null
    ticketsLocked.value = false
    buyingTickets.value = false
    paymentBlocked.value = false
    skipGuard.value = false

    prices.value = []
    promoCode.value = null
    comboPrices.value = []

    festival.value = null
    orderGroupTimer.value = null
    orderData.value = null
    paymentData = reactive({})
    thanksData.value = null
  }

  return {
    selectedEventSlug,
    eventDetails,
    eventLocked,
    showEventUnlockModal,
    showAvailability,
    setSelectedEventSlug,
    setEventDetails,
    unlockEvent,
    hasSeatingPlan,
    eventSelectionMethod,

    orderReserveData,
    showMoreEvents,
    setOrderReserveData,
    orderMoreEventsData,
    setOrderMoreEventsData,
    getOrderMoreEvents,
    ticketDataFields,
    orderDataFields,
    showTicketDetails,
    ticket,
    ticketCount,
    ticketCategories,
    setTicketCategories,
    selectedTickets,
    ticketsLocked,
    buyingTickets,
    addToCart,

    dates,
    setEventDates,
    selectedDate,
    setDate,
    selectedSession,
    selectedAreaId,
    seatingPlanPicked,
    setSeatingPlanPicked,
    setAreaId,
    sessionsPending,
    setSession,
    setSessionPending,
    paymentBlocked,

    prices,
    promoCode,
    comboPrices,
    totalPrices,
    reserveTicket,

    festival,
    orderGroupTimer,
    orderData,
    eventTicketsFormData,
    eventOrderFormData,
    setEventTicketsFormData,
    setEventOrderFormData,
    paymentData,
    paymentPending,
    thanksData,
    getTicket,
    setTicket,
    skipGuard,
    setSkipGuard,
    buyTickets,
    updatePrices,
    setPromoCode,
    updateComboPrices,
    updateFestival,
    startOrderGroupTimer,
    extendTimer,
    setPaymentBlocked,
    paymentBlockedRedirect,
    makePayment,
    cancelOrder,
    cancelPending,
    setOrderData,
    setPaymentData,
    setThanksData,
    purchasingBusy,
    purchasingDisabled,
    getSeatingPlanStatelessBase,
    getSeatingPlanStateBase,
    getSeatingPlanArea,
    handleExpiredToken,
    resetStore,

    // export for testing
    handleEventTicketTypesGrouping,
    getDestinationRoute,
    getReservePayload,
    getDataFieldPayload,
    getOrderDataFieldPayload,
    getOrderMoreEventsPayload,
    handleAddToCart,
  }
})
